import React from "react";
import styles from "./css/homepage.module.css";

const TurnDeviceNotification = (props) => {
  return (
    <div
      className={styles.turnDeviceNotification}
      style={{ display: props.orientation === "landscape" ? "none" : "block" }}
    ></div>
  );
};

export default TurnDeviceNotification;
