import * as t from "io-ts";

export const DeliverBuildProgressModel = t.type({
    context: t.type({
        socketId: t.string,
        buildName: t.string,
        buildVersion: t.string,
        clientId: t.number,
    }),
    copiedKey: t.union([t.string, t.undefined]),
    finished: t.number,
    total: t.number,
});

export type DeliverBuildProgress = t.TypeOf<typeof DeliverBuildProgressModel>;
