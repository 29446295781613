import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { Fragment, useCallback, useState } from "react";
import { Region } from "../../generated/graphql";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { green } from "@material-ui/core/colors";
import axios, { AxiosError } from "axios";
import { serverURL } from "../../constants";
import { restPostData } from "../../utils/rest-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

const saveRegion = async (
    data: Partial<Region>,
    onSuccess: (data: any) => void,
    onError: (error: Error) => void
) => {
    try {
        const url = `${serverURL}/${
            data.id && data.id > 0
                ? "admin_update_region"
                : "admin_create_region"
        }`;
        const response = await axios({
            method: "post",
            url,
            data,
            withCredentials: true,
        });
        onSuccess(response.data);
    } catch (error) {
        console.error(error);
        onError(new Error((error as any).message));
    }
};

interface IEditRegionButtonProps {
    region: Partial<Region>;
}

export const EditRegionButton = (props: IEditRegionButtonProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const handleClickOpen = useCallback(() => {
        setSubmitionCompleted(false);
        setSubmissionError(null);

        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setSubmitionCompleted(false);
        setSubmissionError(null);

        setOpen(false);
    }, []);

    return (
        <Fragment>
            {props.region && props.region.id && props.region.id > 0 ? (
                <IconButton
                    aria-label="region"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ marginRight: "5px" }}
                >
                    New region
                </Button>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <>
                    <Formik
                        initialValues={props.region || {}}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Required"),
                            label: Yup.string().required("Required"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            saveRegion(
                                values,
                                (_responseData: any) => {
                                    setSubmitting(false);
                                    if (!_responseData.ok) {
                                        setSubmissionError(_responseData.error);
                                    } else {
                                        // Possibly Call onManagedRegionsChanged.emit();
                                    }
                                    setSubmitionCompleted(true);
                                },
                                (error) => {
                                    setSubmissionError(error.message);
                                    setSubmitionCompleted(true);
                                }
                            );
                        }}
                    >
                        {({
                            values,
                            isSubmitting,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                        }) => (
                            <Form
                                noValidate
                                style={{
                                    display: isSubmitionCompleted
                                        ? "none"
                                        : "block",
                                }}
                            >
                                <DialogTitle id="form-dialog-title">
                                    Create new region
                                </DialogTitle>
                                <DialogContent>
                                    <TextField
                                        label="Region Name"
                                        name="name"
                                        value={values.name}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        helperText={touched.name && errors.name}
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="Region Label"
                                        name="label"
                                        value={values.label}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            tabIndex={4}
                                            disabled={isSubmitting}
                                            color="primary"
                                        >
                                            Confirm
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </>
                {isSubmitionCompleted && (
                    <>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    `Region successfully ${
                                        props.region ? "updated" : "created"
                                    }`}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={
                                        submissionError
                                            ? handleClickOpen
                                            : handleClose
                                    }
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </Fragment>
    );
};
export default EditRegionButton;
