import { Box, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Instance } from "../generated/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

type PixelStreamingServiceState = "pending" | "ready";

interface IProps {
  TLS: boolean;
  instance: Instance;
}

interface IProgress {
  value: number
}

function CircularProgressWithLabel(props: IProgress) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function ServiceStateIndicator(props: IProps) {
  const [bootstrapProgress, setBootstrapProgress] = useState<number>(0);
  const [hoverOnLink, setHoverOnLink] = useState(false);
  const [hoverOnCopyIcon, setHoverOnCopyIcon] = useState(false);
  const [showCopyHrefDone, setShowCopyHrefDone] = useState(false);
  const serviceState: PixelStreamingServiceState = props.instance.bootstrapState === "ready" ? "ready" : "pending";

  const classes = useStyles();
  const href = props.TLS ? `https://${props.instance.domainName}` : `http://${props.instance.publicIpAddress}`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (serviceState === "pending") {
        //to avoid stuck at 99% for too long
        const reservation = 60000;
        const newProgress = (new Date().getTime() - (new Date(props.instance.lastStartTime).getTime())) /
          ((props.instance.estimateTotalStartupTime || 540000) + reservation);
        setBootstrapProgress(Math.min(newProgress, 0.99));
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [serviceState, props.instance.lastStartTime, props.instance.estimateTotalStartupTime]);

  const copyLink = (_href: string) => {
    navigator.clipboard.writeText(_href)
    setShowCopyHrefDone(true);
    setTimeout(() => setShowCopyHrefDone(false), 4000);
  }

  return (
    <div className={classes.root}>
      {serviceState === "ready" && (
        <Grid container spacing={2}>
          <Grid item xs={9} style={{ whiteSpace: 'nowrap' }}
            onMouseEnter={() => setHoverOnLink(true)}
            onMouseLeave={() => setTimeout(() => setHoverOnLink(false), 4000)}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={href}
            >
              {href}
            </a>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={showCopyHrefDone ? "Copied" : "Copy Url"} placement="top">
              <FileCopyOutlinedIcon
                fontSize="small"
                style={{ cursor: "hand", visibility: (hoverOnLink || hoverOnCopyIcon) ? "visible" : "hidden" }}
                onMouseOver={() => setHoverOnCopyIcon(true)}
                onMouseOut={() => setHoverOnCopyIcon(false)}
                onClick={() => copyLink(href)}
              />
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {serviceState !== "ready" && (
        <React.Fragment>
          <div><CircularProgressWithLabel value={Math.floor(bootstrapProgress * 100.0)} /></div>
        </React.Fragment>
      )}
    </div>
  );
}
