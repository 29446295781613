import {
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { serverURL } from "../../constants";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

async function changePassword(
    data: { email: string; password: string },
    onSuccess: (data: any) => void,
    onError: (error: Error) => void
) {
    try {
        const response = await axios({
            method: "post",
            url: serverURL + "/chg_pwd",
            data,
            withCredentials: true,
        });
        onSuccess(response.data);
    } catch (error) {
        console.error(error);
        onError(new Error((error as any).message));
    }
}

interface IChangePasswordButtonProps {
    email: string;
    useIcon: boolean;
}

export default function ChangePasswordButton(
    props: IChangePasswordButtonProps
) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const handleClickOpen = () => {
        setSubmitionCompleted(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            {props.useIcon && (
                <Tooltip
                    title="Change password"
                    arrow
                    placement="left"
                    TransitionComponent={Zoom}
                >
                    <IconButton
                        aria-label="users"
                        color="primary"
                        onClick={handleClickOpen}
                    >
                        <VpnKeyIcon />
                    </IconButton>
                </Tooltip>
            )}
            {!props.useIcon && (
                <Button
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ marginRight: "5px" }}
                >
                    Change Password
                </Button>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <Formik
                            initialValues={{
                                email: props.email,
                                password: "",
                                passwordConfirmation: "",
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(
                                        6,
                                        "Password is too short - should be 6 chars minimum."
                                    ),
                                passwordConfirmation: Yup.string()
                                    .required(
                                        "No confirmation password provided."
                                    )
                                    .oneOf(
                                        [Yup.ref("password"), ""],
                                        "Passwords must match"
                                    ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                changePassword(
                                    values,
                                    (_responseData: any) => {
                                        setSubmitting(false);
                                        setSubmitionCompleted(true);
                                        if (!_responseData.ok) {
                                            setSubmissionError(
                                                _responseData.error
                                            );
                                        }
                                    },
                                    (error) => {
                                        setSubmitionCompleted(true);
                                        setSubmissionError(error.message);
                                    }
                                );
                            }}
                        >
                            {({
                                values,
                                isSubmitting,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                            }) => (
                                <Form>
                                    <DialogTitle id="form-dialog-title">
                                        Change Password for {props.email}
                                    </DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            label="New Password"
                                            name="password"
                                            type="password"
                                            inputProps={{ tabIndex: 2 }}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                touched.password &&
                                                errors.password
                                            }
                                            disabled={isSubmitting}
                                        />
                                        <TextField
                                            label="New Password Confirmation"
                                            name="passwordConfirmation"
                                            type="password"
                                            inputProps={{ tabIndex: 3 }}
                                            value={values.passwordConfirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                errors.passwordConfirmation
                                            }
                                            disabled={isSubmitting}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                tabIndex={4}
                                                disabled={isSubmitting}
                                                color="primary"
                                            >
                                                Confirm
                                            </Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            )}
                                        </div>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Password successfully changed "}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </Fragment>
    );
}
