import {
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import React, { useEffect, useState } from "react";
import { ClientModelT } from "../../domain/ClientModel";
import { User } from "../../domain/user";
import { onManagedUsersChanged } from "../../event-hub";
import { GameUser } from "../../generated/graphql";
import PixelStreamingApplication from "../../utils/application";
import ChangePasswordButton from "./chgpwd-button";
import DeleteButton from "./delete-button";
import NewUserButton from "./newuser-button";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 350,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
    })
);

interface IUserAdminPanelProps {
    onlineUsers: string[];
    me: User;
    setOpenBackdrop: any;
}

const UserAdminPanel = (props: IUserAdminPanelProps) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<GameUser[]>([]);
    const [clients, setClients] = useState<ClientModelT[]>([]);

    function fetchUesrs() {
        PixelStreamingApplication.adminFetchUsers().then((result) => {
            if (result.isOk()) {
                const resultUsers = result.unwrapOr([]);
                setUsers(resultUsers);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchUesrs();
    }, []);

    useEffect(() => {
        const disposable = onManagedUsersChanged.on(() => {
            fetchUesrs();
        });
        return () => {
            disposable.dispose();
        };
    }, []);

    useEffect(() => {
        PixelStreamingApplication.adminFetchClients().then((result) => {
            setClients(result.data);
            setLoading(false);
        });
    }, []);

    const isOnline = (email: string) => {
        return props.onlineUsers.includes(email);
    };

    return (
        <Dialog
            className={classes.backdrop}
            open={true}
            fullWidth={false}
            maxWidth={"md"}
        >
            <DialogContent className={classes.container}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ color: "white" }}>
                            User management
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-label="customized table"
                                    size={"small"}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                Client
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Email
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Role
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Online
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                Operations
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(users || []).map((user) => (
                                            <StyledTableRow key={user.email}>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {(() => {
                                                        const filteredClients =
                                                            clients.filter(
                                                                (client) =>
                                                                    client.id ===
                                                                    user.clientId
                                                            );
                                                        if (
                                                            filteredClients.length >
                                                            0
                                                        ) {
                                                            return filteredClients[0]
                                                                .name;
                                                        }
                                                        return "";
                                                    })()}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {user.email}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {user.role}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {isOnline(user.email) && (
                                                        <CheckIcon />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        justify="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            {user.email !==
                                                                props.me
                                                                    .email &&
                                                                !isOnline(
                                                                    user.email
                                                                ) && (
                                                                    <DeleteButton
                                                                        email={
                                                                            user.email
                                                                        }
                                                                    />
                                                                )}
                                                        </Grid>
                                                        <Grid item>
                                                            {user.email !==
                                                                props.me
                                                                    .email &&
                                                                !isOnline(
                                                                    user.email
                                                                ) && (
                                                                    <ChangePasswordButton
                                                                        email={
                                                                            user.email
                                                                        }
                                                                        useIcon={
                                                                            true
                                                                        }
                                                                    />
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonGroup
                                color="primary"
                                aria-label="outlined secondary button group"
                            >
                                <NewUserButton />
                                <Button
                                    onClick={() => props.setOpenBackdrop(false)}
                                >
                                    Close
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default UserAdminPanel;
