import {
    Backdrop,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import {
    ClientModelT,
    SelectedClientState
} from "../../domain/ClientModel";
import { LaunchTemplateModelT } from "../../domain/LaunchTemplateModel";
import { onManagedLaunchTemplatesChanged } from "../../event-hub";
import PixelStreamingApplication from "../../utils/application";
import DeleteLaunchTemplateButton from "./delete-launch-template-button";
import GenerateLaunchTemplateButton from "./generate-template-button";
import SaveTemplateButton from "./savetemplate-button";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 350,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        tableContainer: {
            maxHeight: 350,
        },
    })
);

interface ILaunchTemplatesPanelProps {
    selectedClient: SelectedClientState;
    closePanel: () => void;
}

const LaunchTemplatesPanel = (props: ILaunchTemplatesPanelProps) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState<LaunchTemplateModelT[]>([]);

    function fetchTemplates() {
        if (props.selectedClient) {
            const client = props.selectedClient;
            PixelStreamingApplication.fetchLaunchTemplates().then((result) => {
                setTemplates(
                    result.data.filter((t) => t.clientId === client.id)
                );
                setLoading(false);
            });
        }
    }

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => {
        const disposable = onManagedLaunchTemplatesChanged.on(() => {
            fetchTemplates();
        });
        return () => {
            disposable.dispose();
        };
    }, []);


    return loading ? (
        <Backdrop
            className={classes.backdrop}
            open={props.selectedClient && loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Dialog
            className={classes.backdrop}
            open={props.selectedClient !== false}
            fullWidth={false}
            maxWidth={"md"}
        >
            <DialogContent className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ color: "white" }}>
                        Launch templates management [{PixelStreamingApplication.getCurrentRegion()!.label}]
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer
                            component={Paper}
                            className={classes.tableContainer}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="customized table"
                                size={"small"}
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Template AWS ID
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Template Name
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            Operations
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templates.map((template) => (
                                        <StyledTableRow key={template.id}>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {template.awsId}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {template.name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <SaveTemplateButton
                                                            template={template}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <DeleteLaunchTemplateButton
                                                            template={template}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined secondary button group"
                        >
                            <SaveTemplateButton
                                template={{
                                    id: 0,
                                    clientId: (
                                        props.selectedClient as ClientModelT
                                    ).id,
                                }}
                            />
                            {templates.length === 0 && (
                                <GenerateLaunchTemplateButton client={props.selectedClient as ClientModelT} />
                            )}
                            <Button onClick={props.closePanel}>Close</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default LaunchTemplatesPanel;
