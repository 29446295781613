import {
    Backdrop,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { ClientModelT, SelectedClientState } from "../../domain/ClientModel";
import { onManagedClientsChanged } from "../../event-hub";
import { Region } from "../../generated/graphql";
import PixelStreamingApplication from "../../utils/application";
import SaveInstanceButton from "../instances/saveinstance-button";
import DeleteClientButton from "./delete-client-button";
import LaunchTemplatesPanel from "./launch-templates-panel";
import SaveClientButton from "./saveclient-button";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        tableContainer: {
            maxHeight: 350,
        },
    })
);

interface IClientAdminPanelProps {
    isAdmin: boolean;
    setOpenBackdrop: any;
    regions: Region[];
}

const ClientAdminPanel = (props: IClientAdminPanelProps) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState<ClientModelT[]>([]);
    const [selectedClient, setSelectedClient] =
        useState<SelectedClientState>(false);

    function fetchClients() {
        PixelStreamingApplication.adminFetchClients().then((result) => {
            result.data.forEach((c) => {
                c.accounts_flatten = c.accounts.join(",");
            });
            setClients(result.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchClients();
    }, []);

    useEffect(() => {
        const disposable = onManagedClientsChanged.on(() => {
            fetchClients();
        });
        return () => {
            disposable.dispose();
        };
    }, []);

    return loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Dialog
            className={classes.backdrop}
            open={true}
            fullWidth={false}
            maxWidth={"lg"}
        >
            <DialogContent className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ color: "white" }}>
                        Client management
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer
                            component={Paper}
                            className={classes.tableContainer}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="customized table"
                                size={"small"}
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Client ID
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Client Name
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Region
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            Operations
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients.map((client) => (
                                        <StyledTableRow key={client.id}>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {client.id}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {client.name}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {(client.regions || [])
                                                    .map((region) => {
                                                        const filterRegions =
                                                            props.regions.filter(
                                                                (
                                                                    regionOption
                                                                ) =>
                                                                    regionOption.name ===
                                                                    region
                                                            );
                                                        return filterRegions.length >
                                                            0
                                                            ? filterRegions[0]
                                                                .label
                                                            : "";
                                                    })
                                                    .join(",")}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <SaveClientButton
                                                            client={client}
                                                            regions={
                                                                props.regions
                                                            }
                                                        />
                                                    </Grid>
                                                    {props.isAdmin && (
                                                        <Grid item>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                }}
                                                                onClick={() => {
                                                                    if (client.regions.includes(PixelStreamingApplication.getCurrentRegion()?.name)) {
                                                                        setSelectedClient(
                                                                            selectedClient
                                                                                ? false
                                                                                : client
                                                                        );
                                                                    } else {
                                                                        alert(`client is not in [${PixelStreamingApplication.getCurrentRegion()?.label}] region.`);
                                                                    }
                                                                }}
                                                            >
                                                                Manage
                                                                Launch-Templates
                                                            </Button>

                                                        </Grid>
                                                    )}
                                                    {props.isAdmin && (
                                                        <Grid item>
                                                            <SaveInstanceButton
                                                                clients={[
                                                                    client,
                                                                ]}
                                                                regions={
                                                                    props.regions
                                                                }
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        <DeleteClientButton
                                                            id={client.id}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined secondary button group"
                        >
                            <SaveClientButton
                                regions={props.regions}
                                client={{ id: 0 }}
                            />
                            <Button
                                onClick={() => props.setOpenBackdrop(false)}
                            >
                                Close
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                {selectedClient && (
                    <LaunchTemplatesPanel
                        selectedClient={selectedClient}
                        closePanel={() => setSelectedClient(false)}
                    />

                )}
            </DialogContent>
        </Dialog>
    );
};

export default ClientAdminPanel;
