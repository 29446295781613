import axios, { AxiosError, AxiosResponse } from "axios";
import * as t from "io-ts";
import { Type } from "io-ts";
import _ from "lodash";
import { decodeWith } from "./common";

const decodeResponseWith =
    <ApplicationType = any, EncodeTo = ApplicationType, DecodeFrom = unknown>(
        c: t.Type<ApplicationType, EncodeTo, DecodeFrom>
    ) =>
    (response: AxiosResponse<any>): AxiosResponse<t.TypeOf<typeof c>> => ({
        ...response,
        data: decodeWith(c)(response.data),
    });

const axioRequest = <A, O, I>(
    url: string,
    method: "get" | "post" | "put" | "delete",
    codec: t.Type<A, O, I>,
    data?: any
): Promise<AxiosResponse<A, any>> =>
    axios({
        method,
        url,
        data,
        withCredentials: true,
    }).then(decodeResponseWith(codec));

export const restGetResource = <A>(ti: Type<A>, url: string, data?: any) =>
    axioRequest(url, "get", ti, data);

export const restGetResources = <A>(ti: Type<A>, url: string, data?: any) =>
    axioRequest(url, "get", t.array(ti), data);

export const restPostData = <A>(ti: Type<A>, url: string, data: any) =>
    axioRequest(url, "post", ti, data);

export const restPutData = <A>(ti: Type<A>, url: string, data: any) =>
    axioRequest(url, "put", ti, data);

export const restDeleteData = <A>(ti: Type<A>, url: string, data: any) =>
    axioRequest(url, "delete", ti, data);

export const getAxiosErrorMessage = (error: AxiosError): string =>
    _.get(error, "response.data") || error.message;
