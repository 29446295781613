import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { SERVER_DOMAIN, __prod__ } from "../../constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

function logout(email: string, onError: (error: Error) => void) {
  fetch((__prod__ ? "" : "http://" + SERVER_DOMAIN) + "/logout", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
    credentials: "include",
  })
    .then((r) => r.json())
    .then((data) => {
      window.location.replace("/");
    })
    .catch((err) => {
      onError(err);
    });
}

interface ILogoutButtonProps {
  email: string;
}

export default function LogoutButton(props: ILogoutButtonProps) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setLoading(true);

    logout(props.email, (err: Error) => {
      setLoading(false);
      alert("There's network error, please try again");
      setOpen(false);
    });
  };

  return (
    <div style={{ marginRight: "5px" }}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <span style={{ color: "white" }}>Logout</span>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button onClick={handleLogout} color="primary" disabled={loading}>
              Confirm
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
