import {
    CircularProgress,
    createStyles,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { serverURL } from "../../constants";
import { onManagedUsersChanged } from "../../event-hub";
import { ClientModelT } from "../../domain/ClientModel";
import PixelStreamingApplication from "../../utils/application";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

async function createNewUser(
    data: { clientId: string; email: string; password: string },
    onSuccess: (data: any) => void,
    onError: (error: Error) => void
) {
    try {
        const response = await axios({
            method: "post",
            url: serverURL + "/admin_newuser",
            data,
            withCredentials: true,
        });
        onSuccess(response.data);
    } catch (error) {
        console.error(error);
        onError(new Error((error as any).message));
    }
}

export default function NewUserButton() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState<ClientModelT[]>([]);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const handleClickOpen = () => {
        setSubmitionCompleted(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        PixelStreamingApplication.adminFetchClients().then((result) => {
            setClients(result.data);
            setLoading(false);
        });
    }, []);

    return (
        <Fragment>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                style={{ marginRight: "5px" }}
            >
                New User
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <Formik
                            initialValues={{
                                clientId: "",
                                email: "",
                                password: "",
                                passwordConfirmation: "",
                            }}
                            validationSchema={Yup.object().shape({
                                clientId: Yup.string().required("Required"),
                                email: Yup.string()
                                    .email("Invalid email")
                                    .required("Required"),
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(
                                        6,
                                        "Password is too short - should be 6 chars minimum."
                                    ),
                                passwordConfirmation: Yup.string()
                                    .required(
                                        "No confirmation password provided."
                                    )
                                    .oneOf(
                                        [Yup.ref("password"), ""],
                                        "Passwords must match"
                                    ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                createNewUser(
                                    values,
                                    (_responseData: any) => {
                                        setSubmitting(false);
                                        if (!_responseData.ok) {
                                            setSubmissionError(
                                                _responseData.error
                                            );
                                        } else {
                                            onManagedUsersChanged.emit();
                                        }
                                        setSubmitionCompleted(true);
                                    },
                                    (error) => {
                                        setSubmissionError(error.message);
                                        setSubmitionCompleted(true);
                                    }
                                );
                            }}
                        >
                            {({
                                values,
                                isSubmitting,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                            }) => (
                                <Form autoComplete="off">
                                    <DialogTitle id="form-dialog-title">
                                        Create New User
                                    </DialogTitle>
                                    <DialogContent>
                                        <InputLabel id="client-select-label">
                                            Client
                                        </InputLabel>
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Select
                                                labelId="client-select-label"
                                                name="clientId"
                                                value={values.clientId}
                                                onChange={handleChange}
                                                disabled={isSubmitting}
                                            >
                                                {clients.map((client) => (
                                                    <MenuItem
                                                        value={client.id}
                                                        key={client.id}
                                                    >
                                                        {client.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        <TextField
                                            label="Email"
                                            name="email"
                                            value={values.email}
                                            inputProps={{ tabIndex: 1 }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            autoComplete="off"
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                touched.email && errors.email
                                            }
                                            disabled={isSubmitting}
                                        />
                                        <TextField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            inputProps={{ tabIndex: 2 }}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            autoComplete="off"
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                touched.password &&
                                                errors.password
                                            }
                                            disabled={isSubmitting}
                                        />
                                        <TextField
                                            label="Password Confirmation"
                                            name="passwordConfirmation"
                                            type="password"
                                            inputProps={{ tabIndex: 3 }}
                                            value={values.passwordConfirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            autoComplete="off"
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                errors.passwordConfirmation
                                            }
                                            disabled={isSubmitting}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                tabIndex={4}
                                                disabled={isSubmitting}
                                                color="primary"
                                            >
                                                Confirm
                                            </Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            )}
                                        </div>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " User successfully created "}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </Fragment>
    );
}
