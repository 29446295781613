import {
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { serverURL } from "../../constants";
import {
    LaunchTemplateModel,
    LaunchTemplateModelT,
} from "../../domain/LaunchTemplateModel";
import { onManagedLaunchTemplatesChanged } from "../../event-hub";
import PixelStreamingApplication from "../../utils/application";
import { getAxiosErrorMessage, restPostData } from "../../utils/rest-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

function saveTemplate(
    data: Partial<LaunchTemplateModelT>,
    onSuccess: (data: any) => void,
    onError: (error: AxiosError) => void
) {
    data.region = PixelStreamingApplication.getCurrentRegion().name;
    const url =
        serverURL +
        (data.id && data.id > 0
            ? "/admin_update_launch_template"
            : "/admin_create_launch_template");
    restPostData(LaunchTemplateModel, url, data)
        .then((result) => {
            onSuccess(result.data);
        })
        .catch(onError);
}

interface IProps {
    template: Partial<LaunchTemplateModelT> /*for update case*/;
}

export default function SaveTemplateButton(props: IProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const handleClickOpen = () => {
        setSubmitionCompleted(false);
        setSubmissionError(null)

        setOpen(true);
    };

    const handleClose = () => {
        setSubmitionCompleted(false);
        setSubmissionError(null)

        setOpen(false);
    };

    return (
        <Fragment>
            {props.template && props.template.id && props.template.id > 0 ? (
                <IconButton
                    aria-label="template"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ marginRight: "5px" }}
                >
                    Register template
                </Button>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <Formik
                            initialValues={props.template || {}}
                            validationSchema={Yup.object().shape({
                                awsId: Yup.string().required("Required"),
                                name: Yup.string().required("Required"),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                saveTemplate(
                                    values,
                                    (_responseData: LaunchTemplateModelT) => {
                                        setSubmitting(false);
                                        onManagedLaunchTemplatesChanged.emit();
                                        setSubmitionCompleted(true);
                                    },
                                    (error) => {
                                        setSubmissionError(
                                            getAxiosErrorMessage(error)
                                        );
                                        setSubmitionCompleted(true);
                                    }
                                );
                            }}
                        >
                            {({
                                values,
                                isSubmitting,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                            }) => (
                                <Form noValidate>
                                    <DialogTitle id="form-dialog-title">
                                        {props.template &&
                                            props.template.id &&
                                            props.template.id > 0
                                            ? "Update launch-template"
                                            : "Create new launch-template"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            label="AWS Template ID"
                                            name="awsId"
                                            value={values.awsId}
                                            inputProps={{
                                                tabIndex: 1,
                                                autocomplete: "off",
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                touched.name && errors.name
                                            }
                                            disabled={isSubmitting}
                                        />
                                        <TextField
                                            label="Template name"
                                            name="name"
                                            autoComplete=""
                                            value={values.name}
                                            inputProps={{
                                                tabIndex: 1,
                                                autocomplete: "off",
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            margin="normal"
                                            disabled={isSubmitting}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="primary"
                                            disabled={isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                        <div className={classes.wrapper}>
                                            <Button
                                                type="submit"
                                                tabIndex={4}
                                                disabled={isSubmitting}
                                                color="primary"
                                            >
                                                Confirm
                                            </Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={
                                                        classes.buttonProgress
                                                    }
                                                />
                                            )}
                                        </div>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Launch-template successfully " +
                                    (props.template.id &&
                                        props.template.id > 0
                                        ? "updated"
                                        : "created")}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </Fragment>
    );
}
