import * as t from "io-ts";

export const ClientModel = t.intersection([
    t.type({
        id: t.number,
        name: t.string,
        regions: t.array(t.string),
        accounts: t.array(t.string),
        S3bucketName: t.string,
        pssInitUser: t.string,
        pssInitPwd: t.string,
        accessKeyId: t.string,
        secretAccessKey: t.string,
    }),
    t.partial({
        accounts_flatten: t.union([t.string, t.null, t.undefined]),
    }),
]);

export type ClientModelT = t.TypeOf<typeof ClientModel>;

export type SelectedClientState = false | ClientModelT;
