import {
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import axios, { AxiosError } from "axios";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { serverURL } from "../../constants";
import { ClientModel, ClientModelT } from "../../domain/ClientModel";
import { onManagedClientsChanged } from "../../event-hub";
import { Region } from "../../generated/graphql";
import { getAxiosErrorMessage, restPostData } from "../../utils/rest-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

async function saveClient(
    data: Partial<ClientModelT>,
    onSuccess: (data: any) => void,
    onError: (error: AxiosError) => void
) {
    data.accounts = (data.accounts_flatten || "").split(",");
    const url =
        serverURL +
        (data.id && data.id > 0
            ? "/admin_update_client"
            : "/admin_create_client");
    restPostData(ClientModel, url, data)
        .then((result) => onSuccess(result.data))
        .catch(onError);
}

interface IProps {
    client: Partial<ClientModelT> /*for update case*/;
    regions: Region[];
}

export default function SaveClientButton(props: IProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const handleClickOpen = () => {
        setSubmitionCompleted(false);
        setSubmissionError(null)

        setOpen(true);
    };

    const handleClose = () => {
        setSubmitionCompleted(false);
        setSubmissionError(null)

        setOpen(false);
    };

    return (
        <Fragment>
            {props.client && props.client.id && props.client.id > 0 ? (
                <IconButton
                    aria-label="client"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ marginRight: "5px" }}
                >
                    New client
                </Button>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <Fragment>
                    <Formik
                        initialValues={props.client || {}}
                        validationSchema={Yup.object().shape({
                            regions: Yup.array()
                                .required("Required")
                                .min(1),
                            name: Yup.string().required("Required"),
                            accounts_flatten:
                                Yup.string().required("Required"),
                            S3bucketName: Yup.string().required("Required"),
                            pssInitUser: Yup.string().required("Required"),
                            pssInitPwd: (props.client) ? Yup.string() : Yup.string().required("Required"),
                            accessKeyId: Yup.string().required("Required"),
                            secretAccessKey:
                                Yup.string().required("Required"),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            saveClient(
                                values,
                                (_savedData: ClientModelT) => {
                                    setSubmitting(false);
                                    onManagedClientsChanged.emit();
                                    setSubmitionCompleted(true);
                                },
                                (error: AxiosError) => {
                                    setSubmissionError(
                                        getAxiosErrorMessage(error)
                                    );
                                    setSubmitionCompleted(true);
                                    setSubmitting(false);
                                }
                            );
                        }}
                    >
                        {({
                            values,
                            isSubmitting,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                        }) => (
                            <Form noValidate style={{ display: isSubmitionCompleted ? "none" : "block" }}>
                                <DialogTitle id="form-dialog-title">
                                    {props.client && props.client.id
                                        ? "Update client"
                                        : "Create new client"}
                                </DialogTitle>
                                <DialogContent>
                                    <div id="checkbox-group">Regions</div>
                                    <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                    >
                                        {props.regions.map(
                                            (regionOption) => (
                                                <label
                                                    key={regionOption.id}
                                                >
                                                    <Field
                                                        type="checkbox"
                                                        name="regions"
                                                        value={
                                                            regionOption.name
                                                        }
                                                    />
                                                    {regionOption.label}
                                                </label>
                                            )
                                        )}
                                    </div>
                                    <TextField
                                        label="Client Name"
                                        name="name"
                                        value={values.name}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        helperText={
                                            touched.name && errors.name
                                        }
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="AWS account number"
                                        name="accounts_flatten"
                                        autoComplete=""
                                        value={values.accounts_flatten}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="AWS S3 bucket name"
                                        name="S3bucketName"
                                        autoComplete=""
                                        value={values.S3bucketName}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="PSS initial user name"
                                        name="pssInitUser"
                                        autoComplete=""
                                        value={values.pssInitUser}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="PSS initial user password"
                                        name="pssInitPwd"
                                        type="password"
                                        autoComplete=""
                                        value={values.pssInitPwd}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        placeholder="******"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="AWS access key id"
                                        autoComplete=""
                                        name="accessKeyId"
                                        value={values.accessKeyId}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                    <TextField
                                        label="AWS secret access key"
                                        name="secretAccessKey"
                                        autoComplete=""
                                        value={values.secretAccessKey}
                                        inputProps={{
                                            tabIndex: 1,
                                            autocomplete: "off",
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        margin="normal"
                                        disabled={isSubmitting}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <div className={classes.wrapper}>
                                        <Button
                                            type="submit"
                                            tabIndex={4}
                                            disabled={isSubmitting}
                                            color="primary"
                                        >
                                            Confirm
                                        </Button>
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </div>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Client successfully " +
                                    (props.client ? "updated" : "created")}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={submissionError ? handleClickOpen : handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>
        </Fragment>
    );
}
