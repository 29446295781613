import {
    AppBar,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    makeStyles,
    Slide,
    Theme,
    Toolbar,
    Typography
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/lib/Either"
import { merge, throttle } from "lodash";
import { useSnackbar } from "notistack";
import React, {
    Fragment,
    FunctionComponent,
    useEffect,
    useReducer,
    useState
} from "react";
import { MobileView } from "react-device-detect";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { io } from "socket.io-client";
import "../App.css";
import ManagementPanel from "../components/management-panel";
import LogoutButton from "../components/users/logout-button";
import { ClientModelT } from "../domain/ClientModel";
import { LaunchTemplateModelT } from "../domain/LaunchTemplateModel";
import {
    createEventHub,
    onDeliverBuildProgress,
    onInstanceBootstrapStateChanged,
    onInstanceStateChanged,
    onRegionSwapped
} from "../event-hub";
import { MeQuery, Region } from "../generated/graphql";
import useWindowOrientation from "../hooks/use-window-orientation";
import PixelStreamingApplication from "../utils/application";
import { decodeWith } from "../utils/common";
import InstanceList from "./InstanceList";
import TurnDeviceNotification from "./turn-device-notification";
import { DeliverBuildProgressModel } from "../domain/DeliverBuildProgress";

interface IProtectedAppProps {
    meQuery?: MeQuery;
}

React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function authReducer(state: any, action: any) {
    if (JSON.stringify(state.meQuery) !== JSON.stringify(action.payload)) {
        return { meQuery: action.payload };
    }
    return state;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            marginRight: theme.spacing(2),
        },
        text: {
            padding: theme.spacing(2, 2, 0),
        },
        title: {
            flexGrow: 1,
        },
        paper: {
            paddingBottom: 0,
        },
        list: {
            marginBottom: theme.spacing(2),
        },
        subheader: {
            backgroundColor: theme.palette.background.paper,
        },
        appBar: {
            top: "auto",
        },
        grow: {
            flexGrow: 1,
        },
    })
);

const ProtectedApp: FunctionComponent<IProtectedAppProps> = ({ meQuery }) => {
    const classes = useStyles();

    const props = useWindowOrientation();
    const [viewStyle, setViewStyle] = useState({});
    const [sessionTimeout, setSessionTimeout] = useState(false);
    const [regions, setRegions] = useState<Region[]>([]);

    const [launchTemplates, setLaunchTemplates] = useState<
        LaunchTemplateModelT[]
    >([]);

    const [selectedRegion, setSelectedRegion] = useState<Region>();
    const [clients, setClients] = useState<ClientModelT[]>([]);

    const [authState, authDispatch] = useReducer(authReducer, { meQuery });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const userSession = meQuery!.me!.activeUserSessions?.filter(
        (activeUserSession) => {
            return activeUserSession.email === meQuery!.me!.myEmail;
        }
    )[0];

    function fetchClients() {
        PixelStreamingApplication.adminFetchClients().then((result) => {
            setClients(result.data);
        });
    }

    function fetchRegions() {
        PixelStreamingApplication.fetchRegions().then((result) => {
            if (result.isOk()) {
                const resultRegions = result.value;
                setRegions(resultRegions);
                if (resultRegions.length > 0) {
                    if (meQuery?.me?.preferRegion) {
                        setSelectedRegion(resultRegions.filter(region => region.name === meQuery?.me?.preferRegion)[0]);
                    } else {
                        setSelectedRegion(resultRegions[0]);
                    }
                }
            }
        });
    }
    useEffect(() => {
        fetchClients();
        PixelStreamingApplication.registerApp();
    }, []);

    useEffect(() => {
        fetchRegions();
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            PixelStreamingApplication.updateCurrentRegion(selectedRegion);
        }
    }, [selectedRegion]);

    useEffect(() => {
        PixelStreamingApplication.fetchLaunchTemplates().then((result) => {
            if (result.status === 200) {
                setLaunchTemplates(result.data);
            }
        });
    }, []);

    useEffect(() => {
        const disposable = onRegionSwapped.on((region) => {
            setSelectedRegion(region);
        });
        return () => {
            disposable.dispose();
        };
    }, []);

    useEffect(() => {
        createEventHub({
            authDispatch,
        });
    }, [enqueueSnackbar, closeSnackbar]);

    merge(props, { setViewStyle, authDispatch });

    useEffect(() => {
        function setHasTouch() {
            window.removeEventListener("touchstart", setHasTouch);
        }
        window.addEventListener("touchstart", setHasTouch);

        return () => {
            window.removeEventListener("touchstart", setHasTouch);
        };
    }, [meQuery]);

    useEffect(() => {
        //early quit if the interval is zero, in case it's about rerender when timeout
        const hearbeatInterval: number = 50000;
        if (hearbeatInterval === 0) return;

        let handler: NodeJS.Timeout | null = null;
        const throttledHeartbeat = throttle(
            PixelStreamingApplication.postHeartbeat,
            hearbeatInterval
        );
        function loop() {
            throttledHeartbeat()?.then((response) => {
                if (handler != null) {
                    clearTimeout(handler);
                }
                if (response.isOk()) {
                    authDispatch({ payload: { me: response.value } });
                } else {
                    setSessionTimeout(true);
                }
                handler = setTimeout(loop, hearbeatInterval);
            });
        }
        loop();

        return () => {
            if (handler != null) {
                clearTimeout(handler);
            }
        };
    }, [authDispatch, setSessionTimeout, meQuery]);

    useEffect(() => {
        const socket = io();
        socket.on("connect", () => {
            console.log("connected");
            PixelStreamingApplication.setCurrentSocketId(socket.id)
        });
        socket.on("update_instance_state", ({ id, state }) => {
            onInstanceStateChanged.emit({ id, state });
        });
        socket.on(
            "update_instance_bootstrap_state",
            ({ id, bootstrapState, lastBootstrapDescription }) => {
                onInstanceBootstrapStateChanged.emit({
                    id,
                    bootstrapState,
                    lastBootstrapDescription,
                });
            }
        );
        socket.on("deliver_build_progress", (data: any) => {
            onDeliverBuildProgress.emit(decodeWith(DeliverBuildProgressModel)(data))
        })
    }, []);

    const handleLoginAgain = () => {
        window.location.replace("/");
    };

    return (
        <div className="noselect">
            {sessionTimeout && (
                <Fragment>
                    <Dialog
                        open={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Session Timeout
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Session timeout, please login again
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleLoginAgain} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Fragment>
            )}
            {!sessionTimeout && (
                <Fragment>
                    <RemoveScrollBar />
                    <MobileView {...props}>
                        <TurnDeviceNotification {...props} />
                    </MobileView>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                {meQuery?.me?.siteTitle}
                            </Typography>
                            {meQuery && regions && regions.length > 0 && (
                                <ManagementPanel
                                    authContext={meQuery}
                                    regions={regions}
                                    viewStyle={{ marginRight: "20px" }}
                                />
                            )}
                            {meQuery && meQuery.me && meQuery.me.myEmail && (
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography
                                        component="h4"
                                        style={{
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                        }}
                                    >
                                        Hi,{meQuery.me.myEmail}
                                    </Typography>
                                    <LogoutButton
                                        email={meQuery.me.myEmail}
                                    />
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    {meQuery && selectedRegion && (
                        <InstanceList
                            region={selectedRegion}
                            launchTemplates={launchTemplates}
                            clients={clients}
                            myClient={meQuery.me!.myClient || 0}
                            isAdmin={["admin"].includes(userSession?.userRole!)}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default ProtectedApp;
