import {
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    TextField,
    Theme
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlayCircleFilledWhiteOutlinedIcon from "@material-ui/icons/PlayCircleFilledWhiteOutlined";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { serverURL } from "../../constants";
import { InstanceState } from "../../domain/AWSInstance";
import { onRequestChangeInstanceState } from "../../event-hub";
import { Instance, Region } from "../../generated/graphql";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        largeIcon: {
            width: 60,
            height: 60,
        },
    })
);

async function startStopInstance(
    instanceDbId: number,
    enableLiveStreaming: boolean,
    liveStreamVideoLink: string,
    instanceState: InstanceState,
    onSuccess: (data: any) => void,
    onError: (error: Error) => void
): Promise<string> {
    let result = "";
    let endPoint = "";
    if (instanceState === "stopped" || instanceState === "terminated") {
        endPoint = "/start_instance";
        result = "starting";
    } else if (instanceState === "running") {
        endPoint = "/stop_instance";
        result = "stopping";
    }
    if (endPoint !== "") {
        try {
            const response = await axios({
                method: "post",
                url: serverURL + endPoint,
                data: {
                    instanceDbId,
                    enableLiveStreaming,
                    liveStreamVideoLink,
                },
                withCredentials: true,
            });
            if (response.data.ok) {
                onSuccess(response.data);
            } else {
                console.error(response.data.error);
                onError(new Error(response.data.error));
            }
        } catch (error) {
            console.error(error);
            onError(new Error(error as string));
        }
    }
    return result;
}

interface IProps {
    region: Region;
    myClient: number;
    instance: Instance;
}

class ButtonInfo {
    constructor(public text: string, public disabled: boolean) { }
}

export default function StartStopButton(props: IProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);
    const [open, setOpen] = useState(false);
    const [enableLiveStreaming, setEnableLiveStreaming] = useState(false);
    const [liveStreamVideoLink, setLiveStreamVideoLink] = useState(
        () => props.instance.youtubeLiveStreamLink || ""
    );

    const [operationName, setOperationName] = useState("");

    const isStartButton = ["stopped", "terminated"].includes(
        props.instance.state
    );

    const buttonInfo: ButtonInfo = (() => {
        if (isStartButton) {
            return new ButtonInfo("Start", props.instance.pending);
        } else if (["running"].includes(props.instance.state)) {
            return new ButtonInfo("Stop", props.instance.pending);
        }
        return new ButtonInfo("Progressing...", true);
    })();

    const handleClickOpen = () => {
        // setLoading(false)
        setSubmitionCompleted(false);
        setSubmissionError(null);
        setOpen(true);
        setEnableLiveStreaming(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStartStopInstance = async () => {
        setLoading(true);

        const operation: string = await startStopInstance(
            props.instance.id,
            enableLiveStreaming,
            liveStreamVideoLink,
            props.instance.state as InstanceState,
            (data: any) => {
                setLoading(false);
                setSubmitionCompleted(true);

                onRequestChangeInstanceState.emit(data);
            },
            (err: Error) => {
                setLoading(false);
                setSubmissionError(err.message);
                setSubmitionCompleted(true);
            }
        );
        setOperationName(operation);
    };

    return (
        <div>
            <IconButton
                aria-label={`${buttonInfo.text}`}
                color="primary"
                onClick={handleClickOpen}
                disabled={buttonInfo.disabled}
            >
                {buttonInfo.text.toUpperCase() === "START" ? (
                    <PlayCircleFilledWhiteOutlinedIcon />
                ) : (
                    <HighlightOffIcon />
                )}
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <DialogTitle id="form-dialog-title">
                            Confirmation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {`Are you sure you want to ${buttonInfo.text.toLowerCase()} the pixel streaming instance "${props.instance.name
                                    }"?`}
                                <br />
                            </DialogContentText>
                            {props.myClient !== props.instance.clientId && (
                                <DialogContentText style={{ color: "red" }}>
                                    You are operating on the other client's
                                    instance, be aware all costs will go to
                                    the client's subaccount if configured.
                                </DialogContentText>
                            )}
                            {isStartButton && props.instance.youtubeStreamKey && (
                                <FormControl
                                    component="fieldset"
                                    style={{ width: "100%" }}
                                >
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    onChange={(event) =>
                                                        setEnableLiveStreaming(
                                                            event.target.checked
                                                        )
                                                    }
                                                />
                                            }
                                            label="Enable YouTube Livestreaming"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        aria-label="position"
                                        row
                                        style={{ width: "100%" }}
                                    >
                                        <FormControl style={{ width: "100%" }}>
                                            <TextField
                                                placeholder="Please fill in Youtube Live stream Link"
                                                fullWidth={true}
                                                value={liveStreamVideoLink}
                                                onChange={(event) =>
                                                    setLiveStreamVideoLink(
                                                        event.target.value
                                                    )
                                                }
                                                disabled={!enableLiveStreaming}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </FormControl>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="primary"
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                            <div className={classes.wrapper}>
                                <Button
                                    onClick={handleStartStopInstance}
                                    color="primary"
                                    disabled={loading}
                                >
                                    Confirm
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </DialogActions>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    `Successful ${operationName} the pixel streaming instance "${props.instance.name}"`}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Fragment>
                )}
            </Dialog>
        </div>
    );
}
