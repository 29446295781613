import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { Fragment } from "react";


//be aware that this dialog doesn't include the outer Dialog component
export function MessageDialog(props: { title: string, content: string, onClose: () => void }) {
    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.content}
                </DialogContentText>
                <DialogActions>
                    <Button
                        type="button"
                        className="outline"
                        onClick={props.onClose}
                    >
                        OK
                    </Button>
                </DialogActions>
            </DialogContent>
        </Fragment>
    )
}