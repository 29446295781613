import {
    ButtonGroup,
    CircularProgress,
    createStyles, Grid, List, ListItem, ListItemText, makeStyles,
    TableHead,
    Theme,
    Tooltip,
    Zoom
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AxiosError } from "axios";
import React, { Fragment, useState } from "react";
import { serverURL } from "../../constants";
import { AmazonMachineImage, AmazonMachineImageModel } from "../../domain/AmazonMachineImage";
import { ClientModelT } from "../../domain/ClientModel";
import {
    LaunchTemplateModel,
    LaunchTemplateModelT
} from "../../domain/LaunchTemplateModel";
import { onManagedLaunchTemplatesChanged } from "../../event-hub";
import PixelStreamingApplication from "../../utils/application";
import { restGetResource, restGetResources, restPostData } from "../../utils/rest-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        amiList: {
            backgroundColor: theme.palette.background.paper,
        },
    })
);

async function deleteTemplate(
    template: LaunchTemplateModelT,
    onSuccess: (data: any) => void,
    onError: (error: AxiosError) => void
) {
    const url = serverURL + "/admin_delete_launch_template";
    restPostData(LaunchTemplateModel, url, { id: template.id })
        .then((result) => onSuccess(result.data))
        .catch(onError);
}

interface IProps {
    client: ClientModelT;
}

export default function GenerateLaunchTemplateButton(props: IProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const [images, setImages] = useState<AmazonMachineImage[]>([]);
    const [selectedAMIIndex, setSelectedAMIIndex] = useState<number>(-1);
    const [amiSelectHeader, setAmiSelectHeader] = useState<string>("please choose an AMI to use");

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleFetchingAMI = () => {
        setLoading(true);
        setDialogOpen(true);

        PixelStreamingApplication.fetchAmazonMachineImages().then((result) => {
            setImages(
                result.data.filter((img) => img.Name?.toLowerCase().includes("pixelstreaming"))
            );
            setLoading(false);
        }).catch(e => {
            console.error(e);
            setSubmissionError(e.message);
        });
    }

    const handleAMIListClick = (index: number) => {
        setSelectedAMIIndex(index);
        setAmiSelectHeader(`selected AMI: "${images[index].Name}"`);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);

        setSubmitionCompleted(false);
        setSubmissionError(null);
    };

    const handleGeneration = () => {
        console.log(images[selectedAMIIndex]);
        setLoading(true);
        setSubmitionCompleted(false);
        PixelStreamingApplication.generateLaunchTemplates(props.client, images[selectedAMIIndex]).then((result) => {
            console.log(result);

            onManagedLaunchTemplatesChanged.emit();

            setLoading(false);
            setSubmitionCompleted(true);
            setSubmissionError(null);
        }).catch(e => {
            console.error(e);
            setSubmitionCompleted(true);
            setLoading(false);
            setSubmissionError(e.response.data);
        });
    };

    return (
        <div>
            <Tooltip
                title="Generate launch templates for g4dn instance types, based on preset AMI"
                arrow
                placement="left"
                TransitionComponent={Zoom}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleFetchingAMI}
                    style={{ marginRight: "5px" }}
                >Generate templates with prebuilt AMI</Button>
            </Tooltip>

            <Dialog
                className={classes.backdrop}
                open={dialogOpen}
                fullWidth={false}
                maxWidth={"md"}
            >
                {!isSubmitionCompleted && (
                    <DialogContent className={classes.container}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Grid container spacing={3}>
                                <Grid item xs={12} style={{ color: "white" }}>
                                    Templates Generation -- {amiSelectHeader}
                                </Grid>
                                <Grid item xs={12}>
                                    <List component="nav" aria-label="main AMI list" className={classes.amiList}>
                                        {images.map((image, index) => (
                                            <ListItem
                                                key={index}
                                                button
                                                selected={selectedAMIIndex === index}
                                                onClick={() => handleAMIListClick(index)}
                                            >
                                                <ListItemText primary={image.Name} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonGroup
                                        color="primary"
                                        aria-label="outlined secondary button group"
                                    >
                                        {selectedAMIIndex !== -1 && (
                                            <Button onClick={handleGeneration} style={{ paddingRight: "10px" }}>Generate Launch Templates with AMI</Button>
                                        )}
                                        <Button onClick={handleCloseDialog}>Cancel</Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>
                )}
                {isSubmitionCompleted && (
                    <Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Templates successfully generated "}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleCloseDialog}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Fragment>
                )}
            </Dialog>

        </div>
    );
}
