import {
    Button,
    ButtonGroup,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import {
    ThemeProvider,
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { User } from "../domain/user";
import { onRegionSwapped } from "../event-hub";
import { MeQuery, Region } from "../generated/graphql";
import ChangePasswordButton from "./users/chgpwd-button";
import ClientAdminPanel from "./users/client-admin-panel";
import UserAdminPanel from "./users/user-admin-panel";
import RegionAdminPanel from "./users/region-admin-panel";
import * as t from "io-ts";
import { serverURL } from "../constants";
import { restPostData } from "../utils/rest-client";

interface IPlayerListProps {
    authContext: MeQuery | undefined;
    regions: Region[];
    viewStyle: any;
}

const ManagementPanel = (props: IPlayerListProps) => {
    const [openRegionAdmin, setOpenRegionAdmin] = useState(false);
    const [openUserAdmin, setOpenUserAdmin] = useState(false);
    const [openClientAdmin, setOpenClientAdmin] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(() => {
        const region = props.authContext?.me?.preferRegion;
        return region ? props.regions.findIndex((r) => r.name === region) : 0;
    });

    const [users, setUsers] = useState([] as Array<User>);
    const [me, setMe] = useState({} as User);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (
        _event: React.MouseEvent<HTMLElement>,
        index: number
    ) => {
        if (selectedIndex !== index) {
            onRegionSwapped.emit(props.regions[index]);
            setSelectedIndex(index);

            restPostData(t.string, serverURL + "/update_prefer_region", {
                preferRegion: props.regions[index].name,
            })
                .then((result) =>
                    console.log(`prefer region swapped to ${result.data}`)
                )
                .catch((err) =>
                    console.error(
                        `error occured when trying to swap region to ${props.regions[index].name}. error: `,
                        err
                    )
                );
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        const newUsers: Array<User> = [];
        const meQueryResult = props.authContext?.me!;
        const myEmail = meQueryResult.myEmail;

        meQueryResult.activeUserSessions?.forEach((activeUserSession) => {
            const user = new User(activeUserSession);
            if (myEmail === user.email) {
                user.isCurrentUser = true;
                user.isSupervisor = meQueryResult.myRole === "supervisor";
                setMe(user);
            }
            newUsers.push(user);
        });
        newUsers.sort((a, b) => (a.email < b.email ? -1 : 1));
        setUsers(newUsers);
    }, [props.authContext]);

    const theme = createMuiTheme({
        palette: {
            primary: orange,
            secondary: orange,
        },
    });

    return (
        <div style={props.viewStyle}>
            <ThemeProvider theme={theme}>
                <ButtonGroup
                    variant="text"
                    color="primary"
                    aria-label="admin"
                    disableElevation
                >
                    {props.regions.length > 1 && (
                        <>
                            <List component="nav" aria-label="Region">
                                <ListItem
                                    button
                                    aria-haspopup="true"
                                    aria-controls="lock-menu"
                                    aria-label="Select Region"
                                    onClick={handleClickListItem}
                                >
                                    <ListItemText
                                        primary={
                                            props.regions[selectedIndex].label
                                        }
                                        style={{ color: "orange" }}
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {props.regions.map((region, index) => (
                                    <MenuItem
                                        key={region.id}
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                            handleMenuItemClick(event, index)
                                        }
                                    >
                                        {region.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    )}

                    {me.isAdmin && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenRegionAdmin(!openRegionAdmin);
                            }}
                        >
                            <span style={{ color: "white" }}>Regions</span>
                        </Button>
                    )}

                    {me.isAdmin && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenUserAdmin(!openUserAdmin);
                            }}
                        >
                            <span style={{ color: "white" }}>Users</span>
                        </Button>
                    )}

                    {me.isAdmin && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenClientAdmin(!openClientAdmin);
                            }}
                        >
                            <span style={{ color: "white" }}>Clients</span>
                        </Button>
                    )}
                    {/* TODO: the icon won't refresh when switching between regions, 
          that's why only use icon when there's only one region */}
                    <ChangePasswordButton
                        email={me.email}
                        useIcon={props.regions.length === 1}
                    />
                </ButtonGroup>
                {openRegionAdmin ? (
                    <RegionAdminPanel setOpenBackdrop={setOpenRegionAdmin} />
                ) : null}
                {openUserAdmin ? (
                    <UserAdminPanel
                        onlineUsers={users.map((u) => u.email)}
                        me={me}
                        setOpenBackdrop={setOpenUserAdmin}
                    />
                ) : null}
                {openClientAdmin ? (
                    <ClientAdminPanel
                        isAdmin={me.isAdmin as boolean}
                        setOpenBackdrop={setOpenClientAdmin}
                        regions={props.regions}
                    />
                ) : null}
            </ThemeProvider>
        </div>
    );
};

export default ManagementPanel;
