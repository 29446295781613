import {
    CircularProgress,
    createStyles,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { AxiosError } from "axios";
import React, { Fragment, useState } from "react";
import { serverURL } from "../../constants";
import { ClientModel } from "../../domain/ClientModel";
import { onManagedClientsChanged } from "../../event-hub";
import { getAxiosErrorMessage, restPostData } from "../../utils/rest-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

async function deleteClient(
    id: number,
    onSuccess: (data: any) => void,
    onError: (error: AxiosError) => void
) {
    const url = serverURL + "/admin_delete_client";
    restPostData(ClientModel, url, { id })
        .then((result) => onSuccess(result.data))
        .catch(onError);
}

interface ILogoutButtonProps {
    id: number;
}

export default function DeleteClientButton(props: ILogoutButtonProps) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setLoading(true);

        deleteClient(
            props.id,
            () => {
                setLoading(false);
                setSubmitionCompleted(true);
                onManagedClientsChanged.emit();
            },
            (err: AxiosError) => {
                setLoading(false);
                setSubmitionCompleted(true);
                setSubmissionError(getAxiosErrorMessage(err));
            }
        );
    };

    return (
        <div>
            <Tooltip
                title="Delete client"
                arrow
                placement="left"
                TransitionComponent={Zoom}
            >
                <IconButton
                    aria-label="client"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <DialogTitle id="form-dialog-title">
                            Confirmation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this client?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="primary"
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                            <div className={classes.wrapper}>
                                <Button
                                    onClick={handleDelete}
                                    color="primary"
                                    disabled={loading}
                                >
                                    Confirm
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </DialogActions>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Client successfully deleted "}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Fragment>
                )}
            </Dialog>
        </div>
    );
}
