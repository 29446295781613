import { DeliverBuildProgress } from "./domain/DeliverBuildProgress";
import { Instance, Region } from "./generated/graphql";
import PixelStreamingApplication from "./utils/application";
import { TypedEvent } from "./utils/TypedEvent";

export const onManagedUsersChanged = new TypedEvent<void>();
export const onManagedClientsChanged = new TypedEvent<void>();
export const onManagedLaunchTemplatesChanged = new TypedEvent<void>();
export const onManagedInstancesChanged = new TypedEvent<void>();
export const onRegionSwapped = new TypedEvent<Region>();

export const onRequestChangeInstanceState = new TypedEvent<string>();
export const onInstanceStateChanged = new TypedEvent<Partial<Instance>>();
export const onInstanceBootstrapStateChanged = new TypedEvent<
    Partial<Instance>
>();
export const onDeliverBuildProgress = new TypedEvent<DeliverBuildProgress>();

interface IEventHubProps {
    authDispatch: (data: any) => void;
}

export function createEventHub(props: IEventHubProps) {
    onManagedUsersChanged.on(() => {
        PixelStreamingApplication.postHeartbeat().then((response) => {
            if (response.isOk()) {
                props.authDispatch({ payload: { me: response.value } });
            }
        });
    });
}

export const adminPanelOpen = new TypedEvent<void>();
