import * as t from "io-ts";

export const AmazonMachineImageModel = t.partial({
    CreationDate: t.union([t.string, t.undefined]),
    ImageId: t.union([t.string, t.undefined]),
    OwnerId: t.union([t.string, t.undefined]),
    Description: t.union([t.string, t.undefined]),
    Name: t.union([t.string, t.undefined]),
});

export type AmazonMachineImage = t.TypeOf<typeof AmazonMachineImageModel>;
