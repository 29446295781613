import { pipe } from "fp-ts/lib/function";
import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import moment from "moment";
import { formatValidationErrors } from "io-ts-reporters";

export const decodeWith =
    <ApplicationType = any, EncodeTo = ApplicationType, DecodeFrom = unknown>(
        codec: t.Type<ApplicationType, EncodeTo, DecodeFrom>
    ) =>
    (input: DecodeFrom): ApplicationType =>
        pipe(
            codec.decode(input),
            E.mapLeft(formatValidationErrors),
            E.getOrElseW((errors) => {
                throw new Error(
                    JSON.stringify(input) + "\n\n" + errors.join("\n")
                );
            })
        );

function mathClamp(value: number, min: number, max: number) {
    return Math.min(Math.max(min, value), max);
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch: number) => {
    const buildDate = moment(epoch).format("DD/MM/YYYY HH:mm");
    return buildDate;
};

export const alertNetworkError = () => {
    window.alert("A network error has occured. Please try again later.");
};

const CommonUtils = {
    mathClamp,
    getBuildDate,
    alertNetworkError,
};

export default CommonUtils;
