import {
    Backdrop,
    Button,
    ButtonGroup,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    Paper,
} from "@material-ui/core";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Region } from "../../generated/graphql";
import PixelStreamingApplication from "../../utils/application";
import { alertNetworkError } from "../../utils/common";
import EditRegionButton from "./edit-region-button";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        tableContainer: {
            maxHeight: 350,
        },
    })
);

type LoadFlags = {
    isCancelled: boolean;
};

interface IRegionAdminPanelProps {
    setOpenBackdrop: Dispatch<SetStateAction<boolean>>;
}

const RegionAdminPanel = (props: IRegionAdminPanelProps) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [regions, setRegions] = useState<Region[]>([]);

    const fetchRegions = useCallback(async (flags: LoadFlags) => {
        const result = await PixelStreamingApplication.fetchRegions();
        if (flags.isCancelled) return;
        if (result.isErr()) {
            alertNetworkError();
            return;
        }
        setRegions(result.value.sort((a, b) => a.id - b.id));
    }, []);

    const load = useCallback(
        async (flags: LoadFlags) => {
            setIsLoading(true);

            await fetchRegions(flags);

            if (flags.isCancelled) return;
            setIsLoading(false);
        },
        [fetchRegions]
    );

    useEffect(() => {
        const flags = { isCancelled: false };
        load(flags);
        return () => {
            flags.isCancelled = true;
        };
    }, [load]);

    return isLoading ? (
        <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Dialog
            className={classes.backdrop}
            open={true}
            fullWidth={false}
            maxWidth={"lg"}
        >
            <DialogContent className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ color: "white" }}>
                        Region management
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer
                            component={Paper}
                            className={classes.tableContainer}
                        >
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="customized table"
                                size={"small"}
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            Region ID
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Region Name
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Region Label
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {regions.map((region) => (
                                        <StyledTableRow key={region.id}>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {region.id}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {region.name}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {region.label}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined secondary button group"
                        >
                            <>
                                <EditRegionButton region={{ id: 0 }} />
                            </>
                            <Button
                                onClick={() => props.setOpenBackdrop(false)}
                            >
                                Close
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default RegionAdminPanel;
