import {
    Button,
    CircularProgress,
    createStyles,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import React, { Fragment, useState } from "react";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

export function ConfirmDialog(props: {
    dialogContent: string;
    onCancel: () => void;
    onConfirm: () => void;
    loadingProps: {
        withLoading: boolean;
        deterministic?: boolean;
        progressValue?: number;
    };
}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        props.onCancel();
    };

    const handleConfirm = () => {
        if (props.loadingProps.withLoading) {
            setLoading(true);
        }
        props.onConfirm();
    };

    return (
        <Fragment>
            <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.dialogContent}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    color="primary"
                    disabled={loading}
                >
                    Cancel
                </Button>
                <div className={classes.wrapper}>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        disabled={loading}
                    >
                        Confirm
                    </Button>
                </div>
                <div className={classes.wrapper}>
                    {loading &&
                        ((props.loadingProps.deterministic && (
                            <CircularProgressWithLabel
                                value={Math.min(
                                    props.loadingProps.progressValue || 1,
                                    99
                                )}
                            />
                        )) || (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        ))}
                </div>
            </DialogActions>
        </Fragment>
    );
}
