import * as t from "io-ts";

export const LaunchTemplateModel = t.type({
    id: t.number,
    awsId: t.string,
    clientId: t.number,
    region: t.string,
    name: t.string,
});

export const LaunchTemplateModels = t.array(LaunchTemplateModel);

export type LaunchTemplateModelT = t.TypeOf<typeof LaunchTemplateModel>;
