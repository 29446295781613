import axios, { AxiosError, AxiosResponse } from "axios";
import { Err, Ok, Result } from "neverthrow";
import { serverURL } from "../constants";
import {
    AmazonMachineImage,
    AmazonMachineImageModel,
} from "../domain/AmazonMachineImage";
import { ClientModel, ClientModelT } from "../domain/ClientModel";
import {
    LaunchTemplateModel,
    LaunchTemplateModels,
    LaunchTemplateModelT,
} from "../domain/LaunchTemplateModel";
import { GameUser, Instance, MeQuery, Region } from "../generated/graphql";
import { restGetResources, restPostData } from "./rest-client";

export interface StreamerCheckerResult {
    ready: boolean;
    progress: number;
}

type IPixelStreamingApplication = {
    uiBlocked: boolean;
    imControlling: () => boolean;
    updateMyControl: (newControl: boolean) => void;
    postHeartbeat: () => Promise<Result<MeQuery, AxiosError>>;
    fetchInstances: (region: string) => Promise<Result<Instance[], AxiosError>>;
    checkStreamer: (
        pssUrl: string
    ) => Promise<Result<StreamerCheckerResult, AxiosError>>;
    adminFetchUsers: () => Promise<Result<GameUser[], AxiosError>>;
    fetchRegions: () => Promise<Result<Region[], Error>>;
    fetchLaunchTemplates: (
        Client?: ClientModelT
    ) => Promise<AxiosResponse<LaunchTemplateModelT[], any>>;
    fetchAmazonMachineImages: () => Promise<
        AxiosResponse<AmazonMachineImage[], any>
    >;
    generateLaunchTemplates: (
        client: ClientModelT,
        ami: AmazonMachineImage
    ) => Promise<AxiosResponse<LaunchTemplateModelT[], any>>;
    adminFetchClients: () => Promise<AxiosResponse<ClientModelT[], any>>;

    updateCurrentRegion: (newRegion: Region) => void;
    getCurrentRegion: () => Region;
    setCurrentSocketId: (newSocketId: string) => void;
    getCurrentSocketId: () => string;

    registerApp: () => void;
};

async function postHeartbeat(): Promise<Result<MeQuery, AxiosError>> {
    try {
        const response = await axios({
            method: "post",
            url: serverURL + "/heartbeat",
            withCredentials: true,
        });
        return new Ok(response.data.result);
    } catch (error) {
        console.error(error);
        return new Err(error as any);
    }
}

async function fetchInstances(
    region: string
): Promise<Result<Instance[], AxiosError>> {
    try {
        const response = await axios({
            method: "post",
            url: serverURL + "/fetch_instances",
            data: { region },
            withCredentials: true,
        });
        return new Ok(response.data.result);
    } catch (error) {
        console.error(error);
        return new Err(error as any);
    }
}

async function checkStreamer(
    pssUrl: string
): Promise<Result<StreamerCheckerResult, AxiosError>> {
    try {
        const response = await axios({
            method: "post",
            url: "/check_streamer",
            data: { pssUrl },
            withCredentials: false,
        });
        return new Ok(response.data.result);
    } catch (error) {
        console.error(error);
        return new Err(error as any);
    }
}

//admin features
async function adminFetchUsers(): Promise<Result<GameUser[], AxiosError>> {
    try {
        const response = await axios({
            method: "post",
            url: "/admin_fetch_users",
            withCredentials: true,
        });
        return new Ok(response.data.result);
    } catch (error) {
        console.error(error);
        return new Err(error as any);
    }
}

async function fetchRegions(): Promise<Result<Region[], Error>> {
    try {
        const response = await axios({
            method: "post",
            url: "/fetch_regions",
            withCredentials: true,
        });
        return new Ok(response.data.result);
    } catch (error) {
        console.error(error);
        return new Err(error as any);
    }
}

async function fetchLaunchTemplates(): Promise<
    AxiosResponse<LaunchTemplateModelT[], any>
> {
    return restGetResources(
        LaunchTemplateModel,
        `/admin_fetch_launch_templates`
    );
}

async function fetchAmazonMachineImages(): Promise<
    AxiosResponse<AmazonMachineImage[], any>
> {
    return restGetResources(AmazonMachineImageModel, `/admin_fetch_ami`);
}

async function generateLaunchTemplates(
    client: ClientModelT,
    ami: AmazonMachineImage
): Promise<AxiosResponse<LaunchTemplateModelT[], any>> {
    return restPostData(
        LaunchTemplateModels,
        "/admin_auto_generate_launch_template",
        {
            client: client.id,
            ami: ami.ImageId,
            region: currentRegion.name,
        }
    );
}

async function adminFetchClients(): Promise<
    AxiosResponse<ClientModelT[], any>
> {
    return restGetResources(ClientModel, "/admin_fetch_clients");
}

let myControl: boolean = false;
let currentRegion: Region;
let socketId: string;

function updateMyControl(newControl: boolean) {
    myControl = newControl;
}

function imControlling(): boolean {
    return myControl;
}

function updateCurrentRegion(newRegion: Region) {
    currentRegion = newRegion;
}

function getCurrentRegion(): Region {
    return currentRegion;
}

function setCurrentSocketId(newSocketId: string) {
    socketId = newSocketId;
}

function getCurrentSocketId(): string {
    return socketId;
}

const PixelStreamingApplication: IPixelStreamingApplication = {
    uiBlocked: false,
    imControlling,
    updateMyControl,
    postHeartbeat,
    fetchInstances,
    checkStreamer,

    adminFetchUsers,
    adminFetchClients,
    fetchRegions,
    fetchLaunchTemplates,
    fetchAmazonMachineImages,
    generateLaunchTemplates,

    updateCurrentRegion,
    getCurrentRegion,
    setCurrentSocketId,
    getCurrentSocketId,

    registerApp: () => {
        //placeholder, for CLI test
    },
};

export default PixelStreamingApplication;
