import {
    Backdrop,
    CircularProgress,
    Grid,
    TablePagination,
} from "@material-ui/core";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { serverURL } from "../../constants";
import { ClientModelT } from "../../domain/ClientModel";
import { InstanceBuild, InstanceBuildModel } from "../../domain/InstanceBuild";
import { restGetResources } from "../../utils/rest-client";
const semver = require("semver");

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 300,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        tableContainer: {
            maxHeight: 860,
        },
        instanceStates: {
            pending: {},
            running: {},
            "shutting-down": {},
            terminated: {},
            stopping: {},
            stopped: {},
        },
    })
);

interface BuildListProps {
    client: ClientModelT;
    onSelected: (buildName: string, buildVersion: string) => void;
}

const BuildList = (props: BuildListProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [instanceBuilds, setInstanceBuilds] = useState<InstanceBuild[]>();
    const [buildNames, setBuildNames] = useState<string[]>();
    const [selectedBuildName, setSelectedBuildName] = useState<string>();
    const [selectedBuildVersion, setSelectedBuildVersion] = useState<string>();

    const tableRefBuild = useRef<HTMLDivElement>(null);
    const tableRefVersion = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.setTimeout(() => {
            if (tableRefBuild.current) {
                const maxHeight = window.innerHeight - 100 + "px";
                tableRefBuild.current.style.maxHeight = maxHeight;
            }
        }, 500);
    });

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClickBuildNameRow = (buildName: string) => {
        console.log(`selected build name:${buildName}`);
        setSelectedBuildName(buildName);
    };

    const handleClickBuildVersionRow = (version: string) => {
        setSelectedBuildVersion(version);
    };

    useEffect(() => {
        setBuildNames(_.uniq(instanceBuilds?.map((t) => t.name)));
    }, [instanceBuilds]);

    useEffect(() => {
        if (selectedBuildName && selectedBuildVersion) {
            props.onSelected(selectedBuildName, selectedBuildVersion);
        }
    }, [selectedBuildVersion]);

    useEffect(() => {
        setLoading(true);

        restGetResources(
            InstanceBuildModel,
            serverURL + `/admin_fetch_instancebuilds/${props.client.id}`
        )
            .then((response) => {
                setInstanceBuilds(response.data);
                setLoading(false);
            })
            .catch((reason) => {
                console.error(reason);
            });
    }, []);

    return loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TableContainer
                    className={classes.tableContainer}
                    ref={tableRefBuild}
                >
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                        size={"medium"}
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Build Name</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(buildNames || [])
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((buildName) => (
                                    <StyledTableRow
                                        key={buildName}
                                        selected={
                                            selectedBuildName === buildName
                                        }
                                        onClick={() =>
                                            handleClickBuildNameRow(buildName)
                                        }
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {buildName}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(buildNames || []).length > 10 && (
                    <TablePagination
                        component="div"
                        count={(buildNames || []).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Grid>
            <Grid item xs={6}>
                <TableContainer
                    className={classes.tableContainer}
                    ref={tableRefVersion}
                >
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                        size={"medium"}
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Build Version</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(instanceBuilds || [])
                                .filter(
                                    (build) => build.name === selectedBuildName
                                )
                                .map((build) => (
                                    <StyledTableRow
                                        key={build.name + "." + build.version}
                                        onClick={() =>
                                            handleClickBuildVersionRow(
                                                build.version
                                            )
                                        }
                                    >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {build.version}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default BuildList;
