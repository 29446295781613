import { useState, useEffect, useCallback } from "react";
import { OrientationType, IWindowOrientationInfo } from '../domain/windows-orientation';
import { throttle } from "lodash";

export default function useWindowOrientation(): IWindowOrientationInfo {
    const [orientation, setOrientation] = useState<OrientationType>("unknown");
    const [width, setWidth] = useState(1920);
    const [height, setHeight] = useState(1080);

    const setNewOrientation = useCallback(throttle(() => {
        const newOrientation =
            window.innerWidth < window.innerHeight ? "portrait" : "landscape";
        setOrientation(newOrientation);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, 1000), [setOrientation, setWidth, setHeight]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        function monitorOrientationChange() {
            timer = setTimeout(() => {
                setNewOrientation();
            }, 200);
        }
        window.addEventListener(
            "orientationchange",
            monitorOrientationChange,
            false
        );
        return () => {
            window.removeEventListener("orientationchange", monitorOrientationChange);
            if (timer) {
                clearTimeout(timer);
            }
        };
    });

    useEffect(() => {
        let resizetimer: ReturnType<typeof setTimeout>;
        function monitorResizeChange() {
            resizetimer = setTimeout(() => {
                setNewOrientation();
            }, 200);
        }
        window.addEventListener(
            "resize",
            monitorResizeChange,
            false
        );
        return () => {
            window.removeEventListener("resize", monitorResizeChange);
            if (resizetimer) {
                clearTimeout(resizetimer);
            }
        };
    });

    useEffect(() => {
        setNewOrientation();
    });
    return {
        orientation,
        width,
        height
    };
}
