import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import log from "loglevel";
import { SnackbarProvider } from "notistack";
import React, { ReactChild, ReactChildren, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import withClearCache from "./ClearCache";
import { SERVER_DOMAIN, __prod__ } from "./constants";
import { MeQuery, useMeQuery } from "./generated/graphql";
import ProtectedApp from "./homepage/ProtectedApp";
import SignIn from "./homepage/SignIn";

document.oncontextmenu = function () {
  return false;
};

if (__prod__) {
  log.setLevel("trace");
} else {
  log.enableAll();
}

const client = new ApolloClient({
  uri: (__prod__ ? "" : "http://" + SERVER_DOMAIN) + "/graphql",
  cache: new InMemoryCache(),
  credentials: "include",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  return (
    <ApolloProvider client={client}>
      <CookieConsent style={{ zIndex: 9999 }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <SnackbarProvider>
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/login">
                <SignIn />
              </Route>
              <PrivateRoute path="/">
                <ProtectedApp />
              </PrivateRoute>
            </Switch>
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ApolloProvider>
  );
}

interface IAuthenticatedProps {
  meQuery: MeQuery;
}

interface AuxProps {
  children: ReactChild | ReactChildren;
  [props: string]: any;
}

function PrivateRoute({ children, ...rest }: AuxProps) {
  const { data: meData, loading } = useMeQuery({
    variables: { pathname: window.location.pathname },
    client,
  });

  useEffect(() => {
    document.title = meData?.me?.siteTitle || "V2i Streamtime";
  }, [meData]);

  // console.log("private route...", meData);

  // console.log(meData?.me);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        loading ? (
          <div style={{ color: "white" }}>Loading...</div>
        ) : meData?.me?.myEmail ? (
          React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { meQuery: meData });
            }
          })
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
