import {
    CircularProgress,
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import React, { Fragment, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { serverURL } from "../../constants";
import { ClientModelT } from "../../domain/ClientModel";
import { InstanceBuild } from "../../domain/InstanceBuild";
import { LaunchTemplateModelT } from "../../domain/LaunchTemplateModel";
import { onManagedInstancesChanged } from "../../event-hub";
import { Instance, Region } from "../../generated/graphql";
import PixelStreamingApplication from "../../utils/application";
import BuildList from "./build-list";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            margin: theme.spacing(1),
            position: "relative",
        },
        buttonSuccess: {
            backgroundColor: green[500],
            "&:hover": {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        errorMessage: { color: "red" },
    })
);

async function saveInstance(
    isUpdate: boolean,
    id: number,
    data: {
        name: string;
        label: string;
        regionName: string;
        clientId: number;
        buildVersion: string;
        engineVersion: string;
        pssVersion: string;
        youtubeStreamKey: string;
        youtubeLiveStreamLink: string;
    },
    onSuccess: (data: any) => void,
    onError: (error: Error) => void
) {
    try {
        const response = await axios({
            method: "post",
            url:
                serverURL +
                (isUpdate
                    ? "/admin_update_instance"
                    : "/admin_create_instance"),
            data: { instanceId: id, instanceData: data },
            withCredentials: true,
        });
        onSuccess(response.data);
    } catch (error) {
        console.error(error);
        onError(new Error(error as string));
    }
}

interface IProps {
    instance?: Instance /*for update case*/;
    clients: ClientModelT[];
    regions?: Region[];
}

export default function SaveInstanceButton(props: IProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [isPickingInstanceBuild, setIsPickingInstanceBuild] =
        useState<boolean>(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);
    const [launchTemplates, setLaunchTemplates] = useState<
        LaunchTemplateModelT[]
    >([]);

    const [selectedBuild, setSelectedBuild] = useState<null | InstanceBuild>(
        null
    );

    const formProps = {
        initialValues: props.instance || {
            name: selectedBuild ? selectedBuild.name : "",
            label: selectedBuild ? selectedBuild.name : "",
            clientId: props.clients[0].id,
            regionName:
                props.clients[0].regions.length > 0
                    ? props.clients[0].regions[0]
                    : "",
            buildVersion: selectedBuild ? selectedBuild.version : "",
            engineVersion: "",
            pssVersion: "",
            youtubeStreamKey: "",
            youtubeLiveStreamLink: "",
            launchTemplateId: getDefaultTemplateId(),
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            buildVersion: Yup.string().required("Required"),
            pssVersion: Yup.string().required("Required"),
            launchTemplateId: Yup.string().required("Required"),
        }),
        onSubmit: (values: any, { setSubmitting }: any) => {
            setSubmitting(true);
            console.log(JSON.stringify(values));
            values.clientId = props.instance
                ? props.instance.clientId
                : props.clients[0].id;
            saveInstance(
                props.instance !== undefined,
                props.instance ? props.instance.id : 0,
                values,
                (_responseData: any) => {
                    setSubmitting(false);
                    if (!_responseData.ok) {
                        setSubmissionError(_responseData.error);
                    } else {
                        onManagedInstancesChanged.emit();
                    }
                    setSubmitionCompleted(true);
                },
                (error) => {
                    setSubmissionError(error.message);
                    setSubmitionCompleted(true);
                }
            );
        },
    };

    const formik = useFormik(formProps);

    const handleClickOpen = () => {
        setSubmitionCompleted(false);
        setSubmissionError(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSubmissionError(null);
    };

    const handlePickingBuild = () => {
        setSelectedBuild(null);
        setIsPickingInstanceBuild(true);
    };

    const handleClosePickingInstanceBuildDialog = () => {
        setIsPickingInstanceBuild(false);
    };

    const handleInstanceBuildPicked = () => {
        if (formik && selectedBuild) {
            formik.setFieldValue("name", selectedBuild.name);
            formik.setFieldValue("label", selectedBuild.name);
            formik.setFieldValue("buildVersion", selectedBuild.version);
        }
        setIsPickingInstanceBuild(false);
    };

    function getDefaultTemplateId() {
        if (launchTemplates && launchTemplates.length > 0) {
            return launchTemplates[0].id;
        }
        return "";
    }

    useEffect(() => {
        if (open) {
            PixelStreamingApplication.fetchLaunchTemplates().then((result) => {
                if (result.status === 200) {
                    const resultTemplates =
                        props.clients.length > 0
                            ? result.data.filter(
                                  (template) =>
                                      template.clientId === props.clients[0].id
                              )
                            : result.data;
                    setLaunchTemplates(resultTemplates);
                }
            });
        }
    }, [open, props.clients, props.instance, setLaunchTemplates]);

    return (
        <Fragment>
            {props.instance ? (
                <IconButton
                    aria-label="client"
                    color="primary"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            ) : (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    style={{ marginRight: "5px" }}
                >
                    New instance
                </Button>
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {!isSubmitionCompleted && (
                    <Fragment>
                        <form onSubmit={formik.handleSubmit}>
                            <DialogTitle id="form-dialog-title">
                                {props.instance
                                    ? "Update instance"
                                    : "Create new instance"}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Instance Name *"
                                            name="name"
                                            value={formik.values.name}
                                            inputProps={{ tabIndex: 1 }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            fullWidth
                                            margin="normal"
                                            helperText={
                                                formik.touched.name &&
                                                formik.errors.name
                                            }
                                            disabled={
                                                props.instance !== undefined ||
                                                formik.isSubmitting
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {props.instance === undefined &&
                                            props.clients[0].name !== "v2i" &&
                                            !formik.isSubmitting && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handlePickingBuild}
                                                >
                                                    Pick Build/Version
                                                </Button>
                                            )}
                                    </Grid>
                                </Grid>
                                <TextField
                                    label="Instance Title *"
                                    name="label"
                                    placeholder="Leave empty if it's the same as name"
                                    value={formik.values.label}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />
                                {!props.instance && (
                                    <Select
                                        labelId="Region"
                                        id="regionId"
                                        name="regionId"
                                        value={formik.values.regionName}
                                        onChange={formik.handleChange}
                                    >
                                        {(props.clients[0].regions || []).map(
                                            (clientRegion) => {
                                                const foundRegions = (
                                                    props.regions || []
                                                ).filter(
                                                    (r) =>
                                                        r.name === clientRegion
                                                );
                                                if (foundRegions.length > 0) {
                                                    const regionOption =
                                                        foundRegions[0];
                                                    return (
                                                        <MenuItem
                                                            value={
                                                                regionOption.name
                                                            }
                                                        >
                                                            {regionOption.label}
                                                        </MenuItem>
                                                    );
                                                } else {
                                                    return <span>Invalid</span>;
                                                }
                                            }
                                        )}
                                    </Select>
                                )}
                                <TextField
                                    label="Build Version *"
                                    name="buildVersion"
                                    value={formik.values.buildVersion}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />
                                <TextField
                                    label="Engine Version"
                                    name="engineVersion"
                                    value={formik.values.engineVersion}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />
                                <TextField
                                    label="Signaling Server Version *"
                                    name="pssVersion"
                                    value={formik.values.pssVersion}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />
                                <Select
                                    label="Launch Template *"
                                    id="launchTemplateId"
                                    name="launchTemplateId"
                                    fullWidth
                                    value={formik.values.launchTemplateId}
                                    onChange={formik.handleChange}
                                >
                                    {(launchTemplates || []).map(
                                        (launchTemplate) => {
                                            return (
                                                <MenuItem
                                                    value={launchTemplate.id}
                                                >
                                                    {launchTemplate.name}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>

                                <TextField
                                    label="Youtube Stream Key"
                                    name="youtubeStreamKey"
                                    value={formik.values.youtubeStreamKey}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />

                                <TextField
                                    label="Youtube Live Stream Link"
                                    name="youtubeLiveStreamLink"
                                    value={formik.values.youtubeLiveStreamLink}
                                    inputProps={{ tabIndex: 1 }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    fullWidth
                                    margin="normal"
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={formik.isSubmitting}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="primary"
                                    disabled={formik.isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <div className={classes.wrapper}>
                                    <Button
                                        type="submit"
                                        tabIndex={4}
                                        disabled={formik.isSubmitting}
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                    {formik.isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </div>
                            </DialogActions>
                        </form>
                    </Fragment>
                )}
                {isSubmitionCompleted && (
                    <React.Fragment>
                        <DialogTitle id="form-dialog-title">
                            {submissionError ? "Error" : "Success!"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {submissionError ||
                                    " Instance successfully saved"}
                            </DialogContentText>
                            <DialogActions>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </React.Fragment>
                )}
            </Dialog>

            {isPickingInstanceBuild && (
                <Dialog
                    open={true}
                    aria-labelledby="form-dialog-title"
                    maxWidth={"md"}
                >
                    <DialogTitle id="form-dialog-title">
                        Pick Build/Version
                    </DialogTitle>
                    <DialogContent>
                        <BuildList
                            client={props.clients[0]}
                            onSelected={(name, version) =>
                                setSelectedBuild({
                                    name,
                                    version,
                                    clientId: props.clients[0].id,
                                })
                            }
                        ></BuildList>
                        <DialogActions>
                            {selectedBuild && (
                                <Button
                                    type="button"
                                    className="outline"
                                    variant="outlined"
                                    onClick={handleInstanceBuildPicked}
                                >
                                    Use the Build/Version
                                </Button>
                            )}
                            <Button
                                type="button"
                                className="outline"
                                onClick={handleClosePickingInstanceBuildDialog}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
        </Fragment>
    );
}
