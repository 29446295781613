import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<QueryMeResponse>;
  fetchInstances?: Maybe<Array<Instance>>;
  fetchRegions?: Maybe<Array<Region>>;
};


export type QueryMeArgs = {
  pathname: Scalars['String'];
};

export type QueryMeResponse = {
  __typename?: 'QueryMeResponse';
  error?: Maybe<FieldError>;
  myEmail?: Maybe<Scalars['String']>;
  myRole?: Maybe<Scalars['String']>;
  myClient?: Maybe<Scalars['Int']>;
  myUserRole?: Maybe<Scalars['String']>;
  myControl?: Maybe<Scalars['Boolean']>;
  preferRegion?: Maybe<Scalars['String']>;
  activeUserSessions?: Maybe<Array<ActiveUserSession>>;
  allUsers?: Maybe<Array<GameUser>>;
  siteTitle: Scalars['String'];
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type ActiveUserSession = {
  __typename?: 'ActiveUserSession';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  userRole?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type GameUser = {
  __typename?: 'GameUser';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  role: Scalars['String'];
  clientId: Scalars['Float'];
  clientName: Scalars['String'];
  preferRegion: Scalars['String'];
};

export type Instance = {
  __typename?: 'Instance';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  domainName: Scalars['String'];
  label: Scalars['String'];
  buildVersion: Scalars['String'];
  pssVersion: Scalars['String'];
  youtubeStreamKey: Scalars['String'];
  youtubeLiveStreamLink: Scalars['String'];
  enableLiveStreaming: Scalars['Boolean'];
  regionName: Scalars['String'];
  state: Scalars['String'];
  pending: Scalars['Boolean'];
  bootstrapState: Scalars['String'];
  lastBootstrapDescription: Scalars['String'];
  launchTemplateId: Scalars['Int'];
  instanceId: Scalars['String'];
  publicIpAddress: Scalars['String'];
  privateIpAddress: Scalars['String'];
  pssApiPrivKey: Scalars['String'];
  pssApiPublicKey: Scalars['String'];
  dnsUpsertTimestamp: Scalars['Date'];
  dnsChangeInfoId: Scalars['String'];
  maintenance: Scalars['Boolean'];
  clientId: Scalars['Int'];
  lastUpdated: Scalars['Date'];
  lastStartTime: Scalars['Date'];
  lastReceiveBootstrapTime: Scalars['Date'];
  lastFinishedStartupTime: Scalars['Date'];
  estimateTotalStartupTime: Scalars['Int'];
  lastShutdownTime: Scalars['Date'];
  engineVersion: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  label: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  register: UserResponse;
  login: UserResponse;
  logout: Scalars['Boolean'];
  heartBeat: Scalars['Boolean'];
};


export type MutationRegisterArgs = {
  options: EmailPasswordInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<GameUser>;
  activeUserSession?: Maybe<ActiveUserSession>;
};

export type EmailPasswordInput = {
  clientId: Scalars['Float'];
  email: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'UserResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, user?: Maybe<(
      { __typename?: 'GameUser' }
      & Pick<GameUser, 'email'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MeQueryVariables = Exact<{
  pathname: Scalars['String'];
}>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'QueryMeResponse' }
    & Pick<QueryMeResponse, 'myEmail' | 'myRole' | 'myClient' | 'preferRegion' | 'siteTitle'>
    & { error?: Maybe<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>, activeUserSessions?: Maybe<Array<(
      { __typename?: 'ActiveUserSession' }
      & Pick<ActiveUserSession, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'userRole'>
    )>>, allUsers?: Maybe<Array<(
      { __typename?: 'GameUser' }
      & Pick<GameUser, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'role'>
    )>> }
  )> }
);


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    errors {
      field
      message
    }
    user {
      email
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me($pathname: String!) {
  me(pathname: $pathname) {
    error {
      field
      message
    }
    myEmail
    myRole
    myClient
    preferRegion
    activeUserSessions {
      id
      createdAt
      updatedAt
      email
      userRole
    }
    allUsers {
      id
      createdAt
      updatedAt
      email
      role
    }
    siteTitle
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      pathname: // value for 'pathname'
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;