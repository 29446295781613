import { ActiveUserSession } from "../generated/graphql";

class RecordNotFound {
  found: false = false;
}
class Record {
  found: true = true;
}
type Maybe<T extends Record> = T | RecordNotFound;

export interface IUser {
  email: string;
  isAdmin?: boolean;
}

export enum UserState {
  LOGIN,
  CONNECTED,
  UNKNOWN,
}

export class User implements IUser {
  email: string;
  invitationEmail?: string;
  state: UserState;

  isCurrentUser?: boolean;

  isSupervisor?: boolean;
  isAdmin?: boolean;
  loginTime: Date;

  playerId?: number;
  control?: boolean;
  connectedTime?: Date;

  constructor(activeUserSession: ActiveUserSession) {
    this.email = activeUserSession.email;
    this.isAdmin = activeUserSession.userRole === "admin";
    this.loginTime = new Date();
    this.state = UserState.LOGIN;
  }
}
