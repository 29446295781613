import {
    Backdrop,
    CircularProgress,
    Grid,
    TablePagination,
} from "@material-ui/core";
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import DeleteInstanceButton from "../components/instances/delete-instance-button";
import SaveInstanceButton from "../components/instances/saveinstance-button";
import { ShareBuildButton } from "../components/instances/sharebuild-button";
import StartStopButton from "../components/users/startstop-button";
import { InstanceState } from "../domain/AWSInstance";
import { ClientModelT } from "../domain/ClientModel";
import { LaunchTemplateModelT } from "../domain/LaunchTemplateModel";
import {
    onInstanceBootstrapStateChanged,
    onInstanceStateChanged,
    onManagedInstancesChanged,
    onRequestChangeInstanceState,
} from "../event-hub";
import { Instance, Region } from "../generated/graphql";
import PixelStreamingApplication from "../utils/application";
import ServiceStateIndicator from "./ServiceStateIndicator";
const semver = require("semver");

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
        root: {
            flexGrow: 1,
        },
    })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 350,
            border: "thin solid rgba(255, 152, 0, 0.5)",
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
        container: {
            backgroundColor: "rgb(0 0 0 / 82%)",
            padding: "30px",
            border: "1px solid rgba(255, 145, 0, 0.5)",
        },
        tableContainer: {
            maxHeight: 860,
        },
        instanceStates: {
            pending: {},
            running: {},
            "shutting-down": {},
            terminated: {},
            stopping: {},
            stopped: {},
        },
    })
);

function getInstanceStateStyle(state: InstanceState) {
    if (state === "running") {
        return { color: "green" };
    } else if (state === "stopped") {
        return { color: "red" };
    }
    return { color: "gray" };
}

interface InstanceListProps {
    region: Region;
    clients: ClientModelT[];
    myClient: number;
    launchTemplates: LaunchTemplateModelT[];
    isAdmin: boolean;
}

const fetchInstanceImmediate = (region: Region, callback: any) => {
    PixelStreamingApplication.fetchInstances(region.name).then((okResult) => {
        const resultInstances = okResult.unwrapOr([]);
        resultInstances.forEach(
            (instance) => (instance.regionName = region.label)
        );
        callback(resultInstances);
    });
};

function timeConversion(duration: number) {
    const portions: string[] = [];

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
        portions.push(hours + "h");
        duration = duration - hours * msInHour;
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
        portions.push(minutes + "m");
        duration = duration - minutes * msInMinute;
    }

    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
        portions.push(seconds + "s");
    }

    return portions.join(" ");
}

const throttleFetchInstances = _.throttle(fetchInstanceImmediate, 5000, {
    trailing: false,
});

const InstanceList = (props: InstanceListProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = React.useState(0);
    const [filterClient, setFilterClient] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const tableRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        window.setTimeout(() => {
            if (tableRef.current) {
                const maxHeight = window.innerHeight - 100 + "px";
                tableRef.current.style.maxHeight = maxHeight;
            }
        }, 500);
    });

    useEffect(() => {
        //whenever the region changed, change to page 0
        setPage(0);
    }, [props.region]);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const toggleFilterClient = (clientId: number) => {
        //if currently it's not the first page, reset it to the first one
        setPage(0);
        setFilterClient(filterClient === clientId ? 0 : clientId);
    };

    const [instances, setInstances] = useState<Instance[]>();

    useEffect(() => {
        const goFetchInstances = () => {
            fetchInstanceImmediate(
                props.region,
                (resultInstances: Instance[]) => {
                    setInstances(resultInstances || []);
                    setLoading(false);
                }
            );
        };

        const eventDisposables = [
            onRequestChangeInstanceState.on(goFetchInstances),
            onInstanceStateChanged.on(goFetchInstances),
            onInstanceBootstrapStateChanged.on(goFetchInstances),
            onManagedInstancesChanged.on(goFetchInstances),
        ];

        setLoading(true);
        //initial load
        goFetchInstances();

        const handler: NodeJS.Timeout = setInterval(() => {
            throttleFetchInstances(
                props.region,
                (resultInstances: Instance[]) => {
                    setInstances(resultInstances || []);
                    setLoading(false);
                }
            );
        }, 10000);

        return () => {
            if (handler != null) {
                clearInterval(handler);
            }
            eventDisposables.forEach((disposable) => disposable.dispose());
        };
    }, [props.region]);

    const filteredInstances = (instances || [])
        .filter((instance) =>
            filterClient === 0 ? true : instance.clientId === filterClient
        )
        .sort((a, b) => a.label.localeCompare(b.label));

    return loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TableContainer
                    className={classes.tableContainer}
                    ref={tableRef}
                >
                    <Table
                        className={classes.table}
                        aria-label="customized table"
                        size={"medium"}
                    >
                        <TableHead>
                            <TableRow>
                                {props.isAdmin && (
                                    <StyledTableCell>Client</StyledTableCell>
                                )}
                                {props.isAdmin && (
                                    <StyledTableCell>Region</StyledTableCell>
                                )}
                                <StyledTableCell>Instance</StyledTableCell>
                                <StyledTableCell>Instance Type</StyledTableCell>
                                <StyledTableCell>Build Version</StyledTableCell>
                                <StyledTableCell>
                                    Engine Version
                                </StyledTableCell>
                                <StyledTableCell>
                                    Estimate Launch Time
                                </StyledTableCell>
                                <StyledTableCell>State</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Operations</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {filteredInstances
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((instance) => (
                                    <StyledTableRow key={instance.id}>
                                        {props.isAdmin && (
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    textDecoration:
                                                        instance.clientId ===
                                                        filterClient
                                                            ? "underline"
                                                            : "none",
                                                }}
                                                onClick={() =>
                                                    toggleFilterClient(
                                                        instance.clientId
                                                    )
                                                }
                                            >
                                                {props.clients
                                                    .filter(
                                                        (client) =>
                                                            client.id ===
                                                            instance.clientId
                                                    )
                                                    .map(
                                                        (client) => client.name
                                                    )}
                                            </StyledTableCell>
                                        )}
                                        {props.isAdmin && (
                                            <StyledTableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {instance.regionName}
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {instance.label}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {props.launchTemplates
                                                .filter(
                                                    (template) =>
                                                        template.id ===
                                                        instance.launchTemplateId
                                                )
                                                .map(
                                                    (template) => template.name
                                                )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            title={`Server version:${instance.pssVersion}`}
                                        >
                                            <Grid item>
                                                {instance.buildVersion
                                                    ? `${instance.buildVersion}`
                                                    : ""}

                                                {instance.state ===
                                                    "terminated" &&
                                                    !instance.pending &&
                                                    props.isAdmin && (
                                                        <ShareBuildButton
                                                            clients={
                                                                props.clients
                                                            }
                                                            buildName={
                                                                instance.name
                                                            }
                                                            buildVersion={
                                                                instance.buildVersion
                                                            }
                                                        />
                                                    )}
                                            </Grid>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {instance.engineVersion}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {instance.estimateTotalStartupTime
                                                ? timeConversion(
                                                      instance.estimateTotalStartupTime
                                                  )
                                                : "N/A"}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={getInstanceStateStyle(
                                                instance.state as InstanceState
                                            )}
                                        >
                                            {instance.pending
                                                ? "pending..."
                                                : instance.state}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {!instance.maintenance &&
                                                instance.state === "running" &&
                                                instance.publicIpAddress && (
                                                    <ServiceStateIndicator
                                                        instance={instance}
                                                        TLS={semver.gt(
                                                            instance.pssVersion,
                                                            "1.3.2"
                                                        )}
                                                    />
                                                )}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                                direction="row"
                                            >
                                                <Grid item>
                                                    {instance.maintenance ? (
                                                        <h3>
                                                            <strong>
                                                                The instance is
                                                                in maintenance
                                                                mode
                                                            </strong>
                                                        </h3>
                                                    ) : (
                                                        <StartStopButton
                                                            instance={instance}
                                                            myClient={
                                                                props.myClient
                                                            }
                                                            region={
                                                                props.region
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                {instance.state ===
                                                    "terminated" &&
                                                    !instance.pending &&
                                                    props.isAdmin && (
                                                        <Grid item>
                                                            <SaveInstanceButton
                                                                instance={
                                                                    instance
                                                                }
                                                                clients={props.clients.filter(
                                                                    (client) =>
                                                                        instance.clientId ===
                                                                        client.id
                                                                )}
                                                            />
                                                        </Grid>
                                                    )}
                                                {instance.state ===
                                                    "terminated" &&
                                                    !instance.pending &&
                                                    props.isAdmin && (
                                                        <Grid item>
                                                            <DeleteInstanceButton
                                                                instance={
                                                                    instance
                                                                }
                                                            />
                                                        </Grid>
                                                    )}
                                            </Grid>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {filteredInstances.length > 10 && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filteredInstances.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default InstanceList;
