import { Dialog, IconButton, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import ShareIcon from "@material-ui/icons/Share";
import { AxiosError } from "axios";
import * as t from "io-ts";
import React, { Fragment, useEffect, useState } from "react";
import { serverURL } from "../../constants";
import { ClientModelT } from "../../domain/ClientModel";
import { onDeliverBuildProgress } from "../../event-hub";
import PixelStreamingApplication from "../../utils/application";
import { restPostData } from "../../utils/rest-client";
import { ConfirmDialog } from "../componentshare/ConfirmDialog";
import { MessageDialog } from "../componentshare/MessageDialog";

export const ShareBuildButton = (props: {
    clients: Array<ClientModelT>;
    buildName: string;
    buildVersion: string;
}) => {
    const [openSelect, setOpenSelect] = React.useState(false);
    const [selectedClient, setSelectedClient] = React.useState<ClientModelT>();
    const [confirmMessage, setConfirmMessage] = React.useState("");

    const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
    const [submissionError, setSubmissionError] = useState<null | string>(null);

    const [deliverProgress, setDeliverProgress] = useState<number>(0);

    const handleClickOpen = () => {
        setOpenSelect(true);
    };

    const handleSelectClient = (event: any) => {
        const client = props.clients.filter(
            (c) => c.id === event.target.value
        )[0];
        setSelectedClient(client);
        setOpenSelect(false);
        setConfirmMessage(
            `Are you sure you want to deliver build "${props.buildName}" to "${
                client.name
            } (${client.accounts.join(
                ","
            )})"? depending on the build's size and target region, the process may take a long time.`
        );
    };

    const handleCloseClientSelect = () => {
        setOpenSelect(false);
    };

    const handleCancel = () => {
        setConfirmMessage("");
        setSubmitionCompleted(false);
        setSubmissionError(null);
    };

    const handleConfirm = () => {
        setSubmitionCompleted(false);

        const deliverContent = {
            clientId: selectedClient!.id,
            buildName: props.buildName,
            buildVersion: props.buildVersion,
            socketId: PixelStreamingApplication.getCurrentSocketId(),
        };
        restPostData(
            t.boolean,
            serverURL + `/admin_deliver_build`,
            deliverContent
        )
            .then((result) => {
                console.log(result);
                setConfirmMessage("");
                setSubmitionCompleted(true);
                setOpenSelect(false);
                setDeliverProgress(0);
            })
            .catch((err: AxiosError) => {
                setConfirmMessage("");
                setSubmitionCompleted(true);
                setSubmissionError(String(err.response!.data) || err.message);
                setOpenSelect(false);
                setDeliverProgress(0);
            });
    };
    useEffect(() => {
        const disposable = onDeliverBuildProgress.on((progress) => {
            const displayProgress =
                (progress.finished * 100.0) / progress.total;
            setDeliverProgress(
                displayProgress >= 100.0 ? 99.0 : displayProgress
            );
        });
        return () => {
            disposable.dispose();
        };
    }, []);

    return (
        <Fragment>
            <IconButton
                aria-label="client"
                color="primary"
                onClick={handleClickOpen}
                title="Deliver to a client"
            >
                <ShareIcon />
            </IconButton>
            <Select
                id="sharebutton-controlled-open-select"
                style={{ display: openSelect ? "block" : "none" }}
                open={openSelect}
                onClose={handleCloseClientSelect}
                onChange={handleSelectClient}
                value=""
            >
                {props.clients
                    .filter((c) => c.name !== "v2i")
                    .map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                            {client.name}
                        </MenuItem>
                    ))}
            </Select>
            <Dialog
                open={confirmMessage.length > 0}
                aria-labelledby="form-dialog-title"
            >
                <ConfirmDialog
                    dialogContent={confirmMessage}
                    onCancel={handleCancel}
                    onConfirm={handleConfirm}
                    loadingProps={{
                        withLoading: true,
                        deterministic: true,
                        progressValue: deliverProgress,
                    }}
                />
            </Dialog>
            <Dialog
                open={isSubmitionCompleted}
                aria-labelledby="form-dialog-title"
            >
                <MessageDialog
                    title="Submission completed"
                    content={
                        submissionError || "Submission completed successfully"
                    }
                    onClose={handleCancel}
                />
            </Dialog>
        </Fragment>
    );
};
